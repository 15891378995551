.App {
  width: 100%;
  min-height: 100vh;
  flex-grow: 1;
  background-color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

header {
  min-width: 100%;
  overflow: hidden;
  height: 68px;
  background-color: #668561;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerContent {
  width: 100%;
  max-width: 1500px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topLeftLogo {
  height: 30px;
  border: 3px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  cursor: pointer;
}

.leftPartOfLogo {
  font-weight: 800;
  color: white;
  background-color: #668561;
  padding-left: 5px;
  padding-right: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightPartOfLogo {
  font-weight: 800;
  color: #668561;
  padding-left: 5px;
  padding-right: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer {
  min-width: 100%;
  overflow: hidden;
  background-color: #d7d7d7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0px;
}

.footerContent {
  width: 100%;
  height: 100%;
  max-width: 1500px;
  padding-left: 30px;
  padding-right: 30px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 10px;
}

.mainPage {
  background-color: rgb(235, 235, 235);
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  min-height: 650px;
}

.postContainer {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
}

.bgImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
  border-radius: 10px;
}

.backAndNext {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  width: 100%;
}

.output {
  overflow: hidden;
  position: relative;
  width: 550px;
  height: 550px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
}

.outputMobile {
  overflow: hidden;
  position: relative;
  width: 310px;
  height: 310px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
}

.outputImg {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
}

.viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.topNotes {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 3px;
  width: 300px;
  min-height: 550px;
}

.outputGradient {
  height: 550px;
  width: 550px;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.outputGradientMobile {
  height: 310px;
  width: 310px;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.editPage {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.nameField {
  position: absolute;
  bottom: 94px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 38px;
  font-weight: 900;
  text-shadow: black 1px 0 15px;
}

.nameFieldMobile {
  position: absolute;
  bottom: 53px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 21px;
  font-weight: 900;
  text-shadow: black 1px 0 15px;
}

.nameFieldTwo {
  position: absolute;
  bottom: 59px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 38px;
  font-weight: 900;
  text-shadow: black 1px 0 15px;
}

.nameFieldTwoMobile {
  position: absolute;
  bottom: 33px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 21px;
  font-weight: 900;
  text-shadow: black 1px 0 15px;
}

.extraInfo {
  position: absolute;
  bottom: 34px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  font-style: italic;
  text-shadow: black 1px 0 15px;
}

.extraInfoMobile {
  position: absolute;
  bottom: 19px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 7px;
  font-weight: 700;
  font-style: italic;
  text-shadow: black 1px 0 15px;
}

.nineHoleStretch {
  position: absolute;
  top: 160px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 22px;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  text-shadow: black 1px 0 10px;
}

.nineHoleStretchMobile {
  position: absolute;
  top: 90px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12.3px;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 3.3px;
  text-shadow: black 1px 0 10px;
}

.backNineHoleStretchMobile {
  position: absolute;
  top: 90px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12.3px;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 3.3px;
  text-shadow: black 1px 0 10px;
}

.backNineHoleStretch {
  position: absolute;
  top: 160px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 22px;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  text-shadow: black 1px 0 10px;
}

.verticalLine {
  height: 300px;
  position: absolute;
  top: 170px;
  color: white;
  border-left: 2px solid white;
}

.verticalLineMobile {
  height: 168px;
  position: absolute;
  top: 95px;
  color: white;
  border-left: 1.1px solid white;
}

.finalScoreMobile {
  position: absolute;
  color: white;
  top: 3px;
  font-weight: 900;
  font-size: 54px;
  width: 84px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-shadow: black 1px 0 10px;
}

.finalScoreToParMobile {
  position: absolute;
  top: 0px;
  right: -5.5px;
  color: red;
  font-weight: 900;
  font-size: 20px;
  text-shadow: black 1px 0 10px;
}

.extraInfoTwoMobile {
  width: 168px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 9.5px;
  font-weight: 700;
  font-style: italic;
  text-shadow: black 1px 0 15px;
}

.finalScore {
  position: absolute;
  color: white;
  top: 6px;
  font-weight: 900;
  font-size: 96px;
  width: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-shadow: black 1px 0 10px;
}

.finalScoreToPar {
  position: absolute;
  top: 0px;
  right: -10px;
  color: red;
  font-weight: 900;
  font-size: 36px;
  text-shadow: black 1px 0 10px;
}

.extraInfoTwo {
  width: 300px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 17px;
  font-weight: 700;
  font-style: italic;
  text-shadow: black 1px 0 15px;
}

.bogey {
  border: 2px solid white !important;
}

.birdie {
  border: 2px solid white !important;
  border-radius: 1000px;
}

.double {
  border: 2px solid white !important;
  background-color: white;
  color: black !important;
  text-shadow: none !important;
}

.eagle {
  border: 2px solid white !important;
  border-radius: 1000px;
  background-color: white;
  color: black !important;
  text-shadow: none !important;
}

.bogeyMobile {
  border: 1.5px solid white !important;
}

.birdieMobile {
  border: 1.5px solid white !important;
  border-radius: 1000px;
}

.doubleMobile {
  border: 1.5px solid white !important;
  background-color: white;
  color: black !important;
  text-shadow: none !important;
}

.eagleMobile {
  border: 1.5px solid white !important;
  border-radius: 1000px;
  background-color: white;
  color: black !important;
  text-shadow: none !important;
}

.nineHoleScore {
  margin-top: 10px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
}

.nineHoleScoreMobile {
  margin-top: 1px;
  font-size: 13.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13.5px;
}

.parPostsFooter {
  position: absolute;
  top: 0px;
  width: 140px;
  padding: 5px 10px;
  font-size: 8px;
  background-color: black;
  color: white;
  font-weight: bold;
}

.parPostsFooterMobile {
  position: absolute;
  top: 0px;
  width: 90px;
  padding: 2.8px 5.6px;
  font-size: 5px;
  background-color: black;
  color: white;
  font-weight: bold;
}

.actualNavButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
}

.nextButtonMobile {
  all: unset;
  position: absolute;
  right: -55px;
  cursor: pointer;
  border-radius: 100px;
  padding: 5px;
}

.backButtonMobile {
  all: unset;
  position: absolute;
  left: -55px;
  cursor: pointer;
  border-radius: 100px;
  padding: 5px;
}

.nextButtonMobile:hover {
  background-color: rgb(218, 218, 218);
}
.nextButtonMobile:focus-visible {
  background-color: rgb(218, 218, 218);
}

.backButtonMobile:hover {
  background-color: rgb(218, 218, 218);
}

.nextButtonMobile:focus-visible {
  background-color: rgb(218, 218, 218);
}

.backButtonMobile:focus-visible {
  background-color: rgb(218, 218, 218);
}

.navLocationCircle {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: rgb(203, 203, 203);
}

a {
  cursor: pointer;
}


.privacyContent {
  padding: 20px 50px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-width: 1500px;
}
.privacyPage {
  background-color: rgb(235, 235, 235);
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.footerLink {
  all:unset;
  cursor: pointer;
}


.restartButton {
  all: unset;
  position: absolute;
  right: 0px;
  top: -30px;
  cursor: pointer;
  border-radius: 100px;
  padding: 5px;
}

.restartButton:hover {
  background-color: rgb(218, 218, 218);
}
.restartButton:focus-visible {
  background-color: rgb(218, 218, 218);
}


.menuButton {
  all: unset;
  padding: 5px;
  cursor: pointer;
  font-weight: bold;
  color:white;
  border-radius: 10px;
}

.menuButton:hover {
  background-color: #7da077;
}
.menuButton:focus-visible {
  background-color: #7da077;
}

.purchasePage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 65%;
  max-width: 500px;
  padding: 0px 10px;
  margin-bottom: 20px;
}

.errorInKey {
  position:absolute;
  bottom: 10px;
  color: red;
  left: 100px;
  font-size: 10px;
}

.paid {
  position: absolute;
  bottom: 30px;
  right: 0px;
  background-color: #668561;
  color: white;
  font-weight: 700;
  font-size: 12px;
  padding: 2px 10px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;

}

.homeFlex {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  overflow: hidden;
  flex-wrap: wrap;
}

.carouselContainer {
  margin-top: 20px;
  min-width: 250px;
  width: 40%;
  border-radius: 20px;
  position: relative;
}

.carouselContainerMobile {
  margin: 40px 0px;
  min-width: 250px;
  width: 80%;
  max-width: 500px;
  border-radius: 20px;
  position: relative;
}

.carouselDisclaimer {
  text-align: right;
  font-size: 7px;
  width: 100%;
}

.sampleImage {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.inputField {
  margin-top: 50px;
  width: 90%;
  height: 200px;
  background-color: #eeeeee;
  border-radius: 20px;
  border: 2px dashed rgb(211, 211, 211);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}